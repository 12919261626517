<template>
  <div id="questions">
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <div class="columns">
          <!-- Question -->
          <div class="column is-6-tablet">
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <form>
                <vue-editor />
                <SdInput
                  type="text"
                  rules="required"
                  label="Answer"
                  v-model="question.answer"
                />

                <SdInput
                  type="text"
                  rules="required"
                  label="Score"
                  v-model="question.score"
                />
                <div class="button-group is-pulled-right">
                  <button
                    class="button is-info is-capitalized mt-3 mr-3"
                    @click="reset()"
                  >
                    Reset
                  </button>
                  <button
                    class="button is-success is-capitalized mt-3"
                    @click="passes(add)"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <!-- List -->
          <div class="column is-6-tablet">
            <div class="list">
              <div class="columns is-multiline">
                <div
                  v-for="(item, index) in eCourseWork.questions"
                  class="column is-12 e-courses-list"
                  :key="index + 1"
                >
                  <div class="question-container">
                    <div class="question-index">
                      <span class="number-circle">{{ index + 1 }}</span>
                    </div>
                    <div
                      :id="`question-holder-${index}`"
                      class="question"
                    ></div>
                  </div>
                  <div class="buttons is-right mb-1">
                    <a
                      class="is-small has-text-success"
                      @click="initializeUpdate(index, item)"
                      style="font-size: 0.9em; margin-left: 16px"
                    >
                      <fa-icon icon="edit" size="lg" />
                    </a>
                    <a
                      class="is-small has-text-danger"
                      @click="initializeRemove(index, item)"
                      style="font-size: 0.9em; margin-left: 16px"
                    >
                      <fa-icon icon="trash-alt" size="lg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete -->
    <app-modal context="remove" v-on:remove="remove" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'
import Question from '@/assets/js/question'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import VueEditor from '../../components/vue-editor/VueEditor'

export default {
  data() {
    return {
      pageTitle: 'Questions',
      schoolId: null,
      eCourseWork: {
        questions: [],
      },
      eCourseWorkId: null,
      question: new Question('').create(),
      index: null,
    }
  },
  computed: {
    content() {
      return this.$store.getters.getContent
    },
  },
  watch: {
    eCourseWork(data) {
      if (data.questions) {
        this.$nextTick(() => {
          data.questions.forEach((question, index) => {
            this.populate(index, question.question)
          })
        })
      }
      this.$store.commit('setSubMenus', [
        {
          name: 'E Course Works',
          route: `/school/${this.schoolId}/e_course_works/${data.eCourseTopic.id}`,
        },
        {
          name: 'Bulk Upload',
          route: `/school/${this.schoolId}/e_course_work/${this.$route.params.id}/questions_uploader`,
        },
      ])
    },
    content(question) {
      if (this.index !== null) {
        this.eCourseWork.questions[this.index].question = question
        this.populate(this.index, question)
        this.question.question = question
      } else {
        this.question.question = question
      }
    },
  },
  methods: {
    initializeUpdate(index, item) {
      this.index = index
      this.question = item
      this.$store.dispatch('updateContent', item.question)
    },
    initializeRemove(index, item) {
      this.index = index
      this.openModal()
    },
    add() {
      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateQuestionsForECourseWork(
              $id: Int!
              $index: Int
              $question: JSON!
            ) {
              updateQuestionsForECourseWork(
                input: { id: $id, index: $index, question: $question }
              ) {
                eCourseWork {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: this.eCourseWorkId,
            question: this.question,
            index: this.index,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateQuestionsForECourseWork.errors,
            'Successfully added.'
          ).then(() => {
            this.reset()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteECourseWorkQuestion($id: Int!, $index: Int) {
              deleteECourseWorkQuestion(input: { id: $id, index: $index }) {
                eCourseWork {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: this.eCourseWorkId,
            index: this.index,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteECourseWorkQuestion.errors,
            'Successfully removed.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.eCourseWork.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    populate(index, question) {
      if (!question) return
      const div = document.getElementById(`question-holder-${index}`)
      div.style.wordBreak = 'break-word'
      div.innerHTML = question
    },
    reset() {
      this.$apollo.queries.eCourseWork.refetch()
      this.$store.commit('UPDATE_CONTENT', '')
      this.question = new Question('').create()
    },
  },
  components: {
    SdInput,
    ValidationObserver,
    VueEditor,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.eCourseWorkId = parseInt(this.$route.params.id)

    this.$apollo.addSmartQuery('eCourseWork', {
      query: gql`
        query ECourseWork($id: Int!) {
          eCourseWork(id: $id) {
            id
            questions
            eCourseTopic {
              id
            }
          }
        }
      `,
      variables: {
        id: this.eCourseWorkId,
      },
    })

    this.$apollo.queries.eCourseWork.refetch()
  },
}
</script>

<style lang="scss" scoped>
.e-courses-list {
  font-size: 0.8em;
  border-bottom: orange 1px solid;
  padding: 10px 10px;
}

.question-container {
  display: flex;
  margin-bottom: 5px;
}

.question-index {
  flex: 1;
}

.number-circle {
  height: 30px;
  width: 30px;
  background-color: orange;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
}

.question {
  flex: 90%;
}

.list {
  height: 900px;
  overflow-y: scroll;
  padding: 8px;
}
</style>
